import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import ContactMainForm from '../section/contact_main_form';

 class Sidebar extends Component {
  render(){
    return( 
        <div>
            <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
{/* <li> <NavLink to="/library"  className="bt-sidebar"> &nbsp;Library

 </NavLink>  </li> */}

{/* <li><NavLink to="/"  className="bt-sidebar "> &nbsp;Composit Lab


 </NavLink></li>
<li><NavLink to="/"  className="bt-sidebar "> &nbsp;Computer lab



 </NavLink></li> */}

{/* <li><NavLink to="/resource-room"  className="bt-sidebar "> &nbsp;Resource Room</NavLink></li> */}
<li><NavLink to="/impact"  className="bt-sidebar "> &nbsp;Who is ideal parent at Innovera
</NavLink></li>
<li><NavLink to="/school-docs"  className="bt-sidebar "> &nbsp;CBSE discourses
</NavLink></li>
<li><NavLink to="/school-newsletters"  className="bt-sidebar "> &nbsp;School Newsletters </NavLink></li>
<li><NavLink to="/school-newsletters"  className="bt-sidebar "> &nbsp;School Newsletters </NavLink></li>

</ul>
</div>


 
 <div className="sidebar-item link">
<h4>Call Us</h4>
<hr></hr>
 <ContactMainForm/>
</div>
</div>
        </div>
    );

}
}
export default Sidebar;
