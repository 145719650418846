import React, { Component ,useState, useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import Growth from '../components/section/growth';
import NewsLetter from '../components/section/newsletter';
import Prepare from '../components/section/prepare';
import config from '../constant';
import AboutHomepage from '../components/section/abouthomepage';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import StickyRightButtonWithModal from './stickyBtn';

    const Home = () => {
   
    // componentDidMount(){
    //     document.title = "Home | " + config.SIE_NAME
    //   }

    // const [displayPopUp, setDisplayPopUp] = useState(true);

    // // when pop-up is closed this function triggers
    // const closePopUp = () => {
    //   // setting key "seenPopUp" with value true into localStorage
    //   localStorage.setItem("seenPopUp", true);
    //   // setting state to false to not display pop-up
    //   setDisplayPopUp(false);
    // };
  
    // // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
    // useEffect(() => {
    //   // getting value of "seenPopUp" key from localStorage
    //   let returningUser = localStorage.getItem("seenPopUp");
    //   // if it's not there, for a new user, it will be null
    //   // if it's there it will be boolean true
    //   // setting the opposite to state, false for returning user, true for a new user
    //   //setDisplayPopUp(!returningUser);
    //   setDisplayPopUp(returningUser);

    // }, []);



    // State to control the modal visibility
 const [showModal, setShowModal] = useState(true);

 // Function to close the modal
 const handleClose = () => setShowModal(false);
  // useEffect to open the modal on page load
  useEffect(() => {
    // Show the modal on mount
    setShowModal(true);
  }, []); // Empty dependency array ensures this effect runs only once on mount


    return(
        <div>

<HelmetProvider>
             <meta charSet="utf-8" />
                <title> Innovera School - Best CBSE school in Hadapsar | Magarpatta</title>
	<meta name="description" content="Innovera School is a leading CBSE school in Hadapsar and Magarpatta. We provide best education with latest teaching and learning methods." />
	<link rel="canonical" href="https://innoveraschool.com/?PSE" />   
   </HelmetProvider>
        <Header  />   
        <Slider /> 
         <AboutHomepage/>
         <Growth/>
         {/* <NewsLetter/> */}
         <Prepare/>
        {/* <AboutHome  Name="Abhi" Surname="Jagtap" />     */}
        {/* <Goal isGoal = {true}/> */}
        <StickyRightButtonWithModal/>

        <Footer/>


        {/* <Modal show={displayPopUp} onHide={closePopUp}> */}
        {/* <Modal show={showModal} onHide={handleClose}>

         <Modal.Body><Image src="assets/images/innovera/Innovera-WA.jpg" fluid />
         <h4 className='text-center mt-3'><a href="https://quiz.mi-school.org/index.php?bid=9"  target="_blank">Click here to join!!</a></h4>
         </Modal.Body>
         <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
             Close
           </Button>
           
         </Modal.Footer>
       </Modal> */}


       
         </div>
    );
 
}
export default Home;