import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import config from '../../constant';



class LifeAtInnovera extends Component {
        render() {

    return(
        <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Life ' subtitle='At Innovera'/>
            <Container  >
      <Row>
      <Col lg="9">
              
      

             
 
             <Container className='py-4 mt-5 lifeAtInovera bg-white'>
             
          <Row className=' '>
            <Col lg="5" sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/life_Innovera/Teachers-expectation.jpg )` 
    }} ><div class="caption">Teachers' expectation</div></div>
             
             
            </Col>
            <Col lg="7" sm="7">
                <p>Teachers' are the backbone of any institute. They are not teachers' because of the
money or the glory. They simply want to be known as difference-makers. To have
positive and life-learning impact on students; teachers' need the freedom to think out
of the box and take decisions for the benefit of the school and its students. This is
what we encourage at Innovera School and let teachers' have this freedom.
</p>
            </Col>
          </Row>
</Container>          


<Container className='py-4 lifeAtInovera bg-gray'>

           <Row className=' '>
            <Col lg={{ order:2 , span:5 }} sm="5" >

           <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/life_Innovera/Teachers-expectation.jpg )` 
    }} ><div class="caption">Childrens' expectation</div></div>
           
            </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p>Children or students are the essence of any school or institute. We at Innovera want to create a culture and environment where children should feel welcomed like at home. After all, school is like a second home to them and home is where everyone feels most comfortable. Learning is a process that should be easy and engaging at the same time, and this is what we at Innovera inculcate in the teaching process.</p>
            
            </Col>

           </Row> 
</Container> 

<Container className='py-4 lifeAtInovera bg-white'>

           <Row className=''>
             <Col lg="5" sm="5" >
             <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/life_Innovera/playground.jfif )` 
    }} ><div class="caption">utilisation of ground</div></div>
          
          
            </Col>
            <Col lg="7" sm="7" >
                <p>A playground is an essential part of any school, and Innovera School proudly offers a spacious 8,000-square-meter playground. It serves as a hub for various sports and social activities, fostering both physical and social development. Students engage in outdoor games, team-building exercises, and recreational events that contribute to their overall growth. This vibrant space also encourages a healthy and active lifestyle, ensuring a well-rounded school experience.</p>
            </Col>

          </Row>  
                
 </Container>


 <Container className='py-4 lifeAtInovera bg-gray'>

            <Row className=' '>
 
            <Col lg={{ order:2 , span:5 }} sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/life_Innovera/Indianess-or-ethos.jpg )` 
    }} ><div class="caption">Indianness or ethos</div></div>
          
           </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p>India is known for its cultural values which we want to encourage and inculcate among kids.The important characteristics of Indian Culture are civilized communication, beliefs, values, etiquette, and rituals. India is well known for its 'Unity in Diversity' across the world and this teaches all of us the value of unity and equality.
</p>
            </Col>
          </Row> 
 </Container>
 <Container className='pt-4   lifeAtInovera  '>

          <Row>
          <h1 className=" py-5  mt-0 mb-0">
            <span className="greenBg">Day at</span>
            <span className="greenBor"> Innovera</span>
          </h1>       

            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/0i9r1RbMwC0" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/BHU0SjeQlO4" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/Cf_DgUGH0J4" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/j4FeNFFKNXc" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/b5GTLfOyTSI" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
           
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/5LKdGVsDJt4" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            
         
            <Col sm="4">
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/BbQeKg-o9E4" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            </Row> 
            </Container>
      
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li> <NavLink to="/values"  className="bt-sidebar"> &nbsp;Values </NavLink>  </li>

 <li><NavLink to="/impressions"  className="bt-sidebar "> &nbsp;Impressions</NavLink></li>
 <li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Enroll Now</NavLink></li>
 <li><NavLink to="/annual-sport"  className="bt-sidebar "> &nbsp;Annual sports meet 
  </NavLink></li>
</ul>
</div>

</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default LifeAtInnovera;