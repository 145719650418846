import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FeesForm from "../components/section/fees_form";

const StickyRightButtonWithModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {/* Sticky Button */}
      <div className="buttonFee">
        <Button variant="primary" onClick={handleShow} style={{
            position: "fixed",
            top: "20%",
            right: "0px",
            width: "50px",
            height: "175px",
             zIndex: 1000,
             borderRadius:"0.625rem 0 0 0.625rem" 
  
        }}>
          <span className="btnTxt"  style={{
    transform: "rotate(90deg)",
    margin: "0",
    width: "0",
    whiteSpace: "nowrap",
    transformOrigin: "bottom",
    textAlign: "left",
    position: "absolute",
     fontSize: "14px",
     left:"20px",
    textTransform: "uppercase",
    fontWeight: "800",
    letterSpacing: "0.1em",
    top:"0px"
  }}>Fee Structure</span>
        </Button>
      </div>

      {/* React-Bootstrap Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Details for Fees Structure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Once you fill the form, fee structure will be sent on entered email id.</p>
        <FeesForm/>
        </Modal.Body>
        
      </Modal>
    </div>
  );
};

export default StickyRightButtonWithModal;
