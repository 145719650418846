import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';



class Infrastructure extends Component {
        render() {

    return(
        <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Facilities' subtitle=''/>
            <Container  >
      <Row>
      <Col lg="9">
              

             
 
             <Container className='pt-4 mb-5 lifeAtInovera'>
             
          <Row className='py-4'>
            <Col lg="6" sm="6" >

            <Card>
                    <Card.Img variant="top" src="assets/images/innovera/facilities/Classroom.jpg" />
                    <Card.Body>
                        <Card.Title> Infrastructure  </Card.Title>
                        <Card.Text>
                        The school has well developed instrastructure which consists of spacious and ventilated classrooms, lab, library and resource room.

                        </Card.Text>
                    </Card.Body>
                    </Card>
            
            </Col>
           
          
            <Col lg="6"  sm="6" >
            <Card>
                    <Card.Img variant="top" src="assets/images/innovera/facilities/Lab.jpg" />
                    <Card.Body>
                        <Card.Title> Laboratory </Card.Title>
                        <Card.Text>
                        School building is equipped with a state of the art laboratory and a world class library

                        </Card.Text>
                    </Card.Body>
                    </Card>
            
            </Col>
           
              
 
 
           <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/facilities/School-ground.jpg" />
                    <Card.Body>
                        <Card.Title> Play ground </Card.Title>
                        <Card.Text>
                        Play ground of approx 8000 sq m and an open hall for sports and social activities.
                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
            <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/facilities/AV-room-image.png" />
                    <Card.Body>
                        <Card.Title>AV room </Card.Title>
                        <Card.Text>
                        A humble provision for exposing our children to possible Audio Visual learning. 
                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
            <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/facilities/Open-Hall.jpg" />
                    <Card.Body>
                        <Card.Title>Open hall </Card.Title>
                        <Card.Text>
                        A multipurpose space that accommodates, activities, sports, interactive guest sessions, group dance or yoga. It is one of the favourite joints for children when they rush on the round to eat lunch too. 

                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
            
        
 
 <Col lg="6" sm="6" >
   <Card>
          <Card.Img variant="top" src="assets/images/innovera/facilities/Resourse-Room.png" />
          <Card.Body>
              <Card.Title> Resource  </Card.Title>
              <Card.Text>
              The room accommodates, resource materials for subjects like math, Social Studies and others. It also includes multiple projects made by children, for various projects over time.              </Card.Text>
          </Card.Body>
  </Card>
  </Col>
  <Col lg="6" sm="6" >
   <Card>
          <Card.Img variant="top" src="assets/images/innovera/facilities/LIbrary.jpg" />
          <Card.Body>
              <Card.Title> Library  </Card.Title>
              <Card.Text>
              Our school library is a great place to go and find a variety of books from various authors. 
It is well organized and easy to find what you are looking for.            </Card.Text>
          </Card.Body>
  </Card>
  </Col>
   
</Row> 
            </Container>
      
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li> <NavLink to="/team"  className="bt-sidebar"> &nbsp;Our Team
 </NavLink>  </li>

 <li><NavLink to="/grow-with-us"  className="bt-sidebar "> &nbsp;Grow with us
</NavLink></li>
 <li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Enroll Now</NavLink></li>

 
</ul>
</div>

</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default Infrastructure;