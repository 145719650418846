import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import ContactMainForm from './contact_main_form';
class WhyInnovera extends Component {
        render() {

    return(
        <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Why' subtitle='Innovera'/>
            <Container  >
      <Row>
      <Col lg="9">
             <Container className="py-5  faculty">
            {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">why</span>
            <span className="greenBor"> innovera</span>
          </h1>             */}
            <Row>
                <Col sm="12" lg="12">

                <h1 className=" pb-4  mt-0 mb-0">
            <span className="greenBg">UNIQUE</span>
            <span className="greenBor">  FEATURES</span>
          </h1> 
                 

                {/* <Image src="assets/images/innovera/Innovera-Unique-Feature.png" className='d-none d-sm-block' fluid/>
<Image src="assets/images/innovera/Innovera-Unique-Feature-Mobile.png" className='d-sm-none d-xs-block' fluid/> */}
    
                     </Col>
          <Row className='my-3'>  
             <Col> <Image src="assets/images/innovera/Unique-Feature-1.png" className='d-none d-sm-block' fluid/></Col>
              <Col> <Image src="assets/images/innovera/Unique-Feature-5.png" className='d-none d-sm-block' fluid/></Col>
          </Row>
          <Row className='my-3'>  
             <Col><Image src="assets/images/innovera/Unique-Feature-2.png" className='d-none d-sm-block' fluid/></Col>
              <Col><Image src="assets/images/innovera/Unique-Feature-6.png" className='d-none d-sm-block' fluid/></Col>
          </Row>
          <Row className='my-3'>  
             <Col><Image src="assets/images/innovera/Unique-Feature-3.png" className='d-none d-sm-block' fluid/></Col>
              <Col><Image src="assets/images/innovera/Unique-Feature-7.png" className='d-none d-sm-block' fluid/></Col>
          </Row>
          <Row className='my-3'>  
             <Col><Image src="assets/images/innovera/Unique-Feature-4.png" className='d-none d-sm-block' fluid/></Col>
              <Col><Image src="assets/images/innovera/Unique-Feature-8.png" className='d-none d-sm-block' fluid/></Col>
          </Row>
                  
                
                     {/* <Col sm="12" lg="12">
                        <Image src='assets/images/innovera/Banner_3.jpg' fluid/>
                        </Col> */}
            </Row>

             
            </Container>

            <div className='grayBg mb-5'>
            <Container className='pt-4 mb-5'>
            <h1 className="   mt-0 mb-0">
            {/* <span className="greenBg"></span> */}
            <span className="blueBor"> HOW WE INVOLVE STAKE HOLDERS </span>
          </h1> 
          </Container>

          <Container className='py-4 lifeAtInovera bg-white'>

          <Row className=''>
            <Col lg="5" sm="5" >

            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/beyonds_academics/Strong-Bond.jpg )` 
    }} ><div class="caption">Parent desk</div></div>
           
            
            </Col>
            <Col lg="7" sm="7">
                <p>Maintaining transparency in the school with the parents play the most important part in buidling confidence.  We encourage parents to come on a daily basis and audit the school on the various parameters.
They can spend five minutes to five hours in the premises, sit in any class they wish and observe the lesson, audit the safety and hygiene and submit the report and speak to the senior before leaving."</p>
            </Col>
          </Row>
          </Container>

          <Container className='py-4  lifeAtInovera bg-gray'>

           <Row className=' '>
            <Col lg={{ order:2 , span:5 }} sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/Fostor-Talk.png )` 
    }} ><div class="caption">Foster Talk</div></div>
           
            </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p>Foster Talk is a career focused interaction with children of the senior classes. Where people from different sectors
interact with children and talk about their sector challenges, pathway, benefits and scope. It is an
interactive session where children ask questions to the people whom they are listening to and gain career
guidance through the industry experts. 
</p>
            </Col>
          </Row>

          </Container>
          <Container className='py-4   lifeAtInovera bg-white'>

           <Row className=' '>
            <Col lg="5" sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/Parents-Workshop.png )` 
    }} ><div class="caption">Parents workshop</div></div>
           
 
            </Col>
            <Col lg="7" sm="7" >
                <p>Every month on one saturday, the parents come to school to know what , how and why is going to be taught t the children in the coming month. The teachers share in details and teach the parents the tecniques of same as well the teachniques of taking effective revision. 
</p>
            </Col>
          </Row>  
                <Row className='d-none'>


                    <Col lg="12" >
                    <Card>
                    <Card.Img variant="top" src="assets/images/beyonds_academics/innovera/Strong-Bond.jpg" />
                    <Card.Body>
                        <Card.Title> Parent Desk </Card.Title>
                        <Card.Text>
                        Maintaining transparency in the school with the parents play the most important part in buidling confidence.  We encourage parentst to come on a dily basis and audit the school on the various parameters.
They can spend five minutes to five hours in the premises, sit in any class they wish and observe the lesson, audit the safety and hygiene and submit the report and speak to the senior before leaving.

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="12">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/foster/2.jpeg" />
                    <Card.Body>
                        <Card.Title>Foster Talks </Card.Title>
                        <Card.Text>
                        A group that is a family within the school. That encompases children from class one to class ten in small groups, works as suppport sysem value learning. 

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="12">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/Banner_3.jpg" />
                    <Card.Body>
                        <Card.Title>Parents workshop</Card.Title>
                        <Card.Text>
                        Every month on one saturday, the parents come to school to know what , how and why is going to be taught t the children in the coming month. The teachers share in details and teach the parents the tecniques of same as well the teachniques of taking effective revision. 

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    
                </Row>
            </Container>
            </div>
     
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li><NavLink to="/impact"  className="bt-sidebar "> &nbsp;Impact </NavLink></li>
<li><NavLink to="/impressions"  className="bt-sidebar "> &nbsp;Impressions</NavLink></li>
<li><NavLink to="/team"  className="bt-sidebar "> &nbsp;Our Team

 </NavLink></li>
<li><NavLink to="/life-at-innovera"  className="bt-sidebar "> &nbsp;Day at Innovera

  </NavLink></li>
</ul>
</div>

<div className="sidebar-item link"  >
<h4>Call Us</h4>
<hr></hr>
 <ContactMainForm/>
</div>
 
</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default WhyInnovera;